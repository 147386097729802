import { useEffect } from "react";

import Gl from "./gl";
import Blob from "./gl/Blob";

class WebGLBlobsEffect {
  constructor() {
    this.blobs = [];
    this.addBlobs();
  }

  addBlobs() {
    // size, speed, color, freq, density, strength, offset
    const blob1 = new Blob(2, 0.3, 0.6, 1, 0.08, Math.PI * 1);
    const blob2 = new Blob(3, 0.15, 0.6, 2.0, 0.1, Math.PI * 2);
    const blob3 = new Blob(1, 0.5, 0.6, 1.4, 0.05, Math.PI * 0.5);
    const blob4 = new Blob(0.7, 0.4, 0.6, 1.0, 0.05, Math.PI * 0.5);

    // x, y, z
    blob1.position.set(-11, -7, -2);
    blob2.position.set(10, -5, 1);
    blob3.position.set(-3, -4, -1);
    blob4.position.set(10, 5, 0);

    blob1.rotation.set(-0.4, 0, 0.5);
    blob2.rotation.set(0.4, 1.0, -0.4);
    blob3.rotation.set(0, 0, 0);
    blob4.rotation.set(0, 0.4, 0.5);

    this.blobs = [blob1, blob2, blob3, blob4];

    Gl.scene.add(...this.blobs);
  }
}

export default function useWebGLBlobs() {
  useEffect(() => {
    new WebGLBlobsEffect();
  }, []);
}
