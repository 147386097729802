import React, {useMemo, useCallback} from 'react'
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { Flex } from 'src/components/basic/index';
import { message } from 'antd';


const cx = classNames.bind(styles);

export const Wrapper = (props) => {
  return <div {...props}></div>
}

export const BaseTitle = ({children, className, onClick}) => {
  return <Wrapper className={cx('base-title', className)} onClick={onClick}>{children}</Wrapper>
}

export const BaseContent = ({children, className, onClick}) => {
  return <Wrapper className={cx('base-content', className)} onClick={onClick}>{children}</Wrapper>
}

export const NormalTitle = ({children, className}) => {
  
  return <BaseTitle className={cx('normal-title', className)}>{children}</BaseTitle>
}

export const NormalSubtitle = ({children, className}) => {
    return <BaseTitle className={cx('normal-subtitle', className)}>{children}</BaseTitle>
}

// export const NromalContent = ({children, className}) => {
  
//   return <BaseTitle className={cx('normal-content', className)}>{children}</BaseTitle>
// }

export const NavItem = ({children, className, onClick = () => {}}) => {
  
  return <BaseTitle className={cx('nav-item', className)} onClick={onClick}>{children}</BaseTitle>
}

export const APPButton = ({onClick}) => {
  
  return <Wrapper className={cx('app-button-container')} onClick ={onClick}>
    <BaseTitle className={cx('app-button')}>APP</BaseTitle>
  </Wrapper>
}

export const MarkIcon = () => {
    return <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 1C4.63438 1 1.5 4.13438 1.5 8C1.5 11.8656 4.63438 15 8.5 15C12.3656 15 15.5 11.8656 15.5 8C15.5 4.13438 12.3656 1 8.5 1ZM11.5234 5.71406L8.23281 10.2766C8.18682 10.3408 8.12619 10.3931 8.05595 10.4291C7.98571 10.4652 7.90788 10.4841 7.82891 10.4841C7.74994 10.4841 7.67211 10.4652 7.60187 10.4291C7.53162 10.3931 7.47099 10.3408 7.425 10.2766L5.47656 7.57656C5.41719 7.49375 5.47656 7.37813 5.57813 7.37813H6.31094C6.47031 7.37813 6.62188 7.45469 6.71563 7.58594L7.82813 9.12969L10.2844 5.72344C10.3781 5.59375 10.5281 5.51563 10.6891 5.51563H11.4219C11.5234 5.51563 11.5828 5.63125 11.5234 5.71406Z" fill="currentColor"/>
    </svg>
}

export const DescriptionItem = ({children, className}) => {
  return <Flex gap='4px' className={cx('mobile-description-item', className)} ai='center'>
    <MarkIcon />
    <BaseTitle className={cx('description-item')}>{children}</BaseTitle>
  </Flex>
}


export const BaseCard = ({children, className, style}) => {
    return <Wrapper className={cx('base-card', className)} syle={style}>{children}</Wrapper>
}

export function Image({src, width, height }) {
  const containerStyle= useMemo(() => {
    const style = {}
    if(width){
      style.width = width
    }
    if(height){
      style.height = height
    }
    return style;
  }, [height, width])

  const ImageStyle= useMemo(() => {
    const style = {
      display: 'block'
    }
    if(width){
      style.width = '100%'
    }
    if(height){
      style.height = '100%'
    }
    return style;
  }, [height, width])

  return (
    <div style={containerStyle} >
        <img src={src} style={ImageStyle} alt="" />
    </div>
  )
}


export const SearchButton = ({children, onClick}) => {
  return <Wrapper className={cx('search-button')} onClick={onClick}>
    {children}
  </Wrapper>
}

export function DropDown({
  children,
  className,
  options = [],
  onSelect,
  }){
    const handleSelect = useCallback(
      (val) => {
        onSelect?.(val);
      },
      [onSelect]
    );
  const List = useMemo(() => {
    return options?.map((item,index) => {
      return <div key={index} onClick={() => {handleSelect(item)}}>{ item } </div>
    });
  }, [handleSelect, options]);

  return <div className={cx('dropdown')}>
    <div className={cx('dropdown-children')}>{children}</div>
    <ul className={cx('dropdown-container', className)}>{List}</ul>
  </div>
}


export const scrollToAnchor = (id) => {
  if (id) {
    try {
      let anchorElement = document.querySelector(`#${id}`);
      if (anchorElement) anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    } catch (error) {
      console.log('error >>>', error);
    }
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
};


export const handleComingClick = () => {
  message.info('Comming Soon');
}