import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  forwardRef,
} from "react";

import classes from "./index.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(classes);

const SliderContext = React.createContext({});

const useSlider = () => React.useContext(SliderContext);

export default function Slider({
  children,
  fontFamily,
  fontSize = "110px",
  bgColor = "#000",
  rightText = ".Meta",
  Height = "130px",
}) {
  const childrenState = useState({});

  const currentElementRef = useRef();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [animStart, setAnimStart] = useState(false);

  const [listRepeatCount, setListRepeatCount] = useState(1);

  const [marginTop, setmarginTop] = useState(0);

  useEffect(() => {
    const listHeight =
      Object.values(childrenState[0]).reduce((perv, next) => perv + next, 0) +
      1;

    const offsetHeight = currentElementRef.current.offsetHeight;

    const count = Math.ceil(offsetHeight / listHeight) + 1;

    setListRepeatCount(count);
  }, [childrenState]);

  const [childHeight, setChildHeight] = useState(0);
  const [topCount, settopCount] = useState(0);

  useEffect(() => {
    const offsetHeight = currentElementRef.current.offsetHeight;
    const childHeight = childrenState[0][0];

    if (!childHeight) {
      return;
    }

    setChildHeight(childHeight);

    const topCount = ((offsetHeight / childHeight + 0.5) / 2) >> 0;

    settopCount(topCount % Object.keys(childrenState[0]).length);

    const marginTop =
      (((offsetHeight / childHeight + 0.5) / 2) >> 0) * childHeight;

    setmarginTop(marginTop);
  }, [childrenState]);

  useEffect(() => {
    const currentElement = currentElementRef.current || {};

    const listener = () => {
      setCurrentIndex((v) => {
        return v + 1 === children.length ? 0 : v + 1;
      });
      setAnimStart(false);
    };

    currentElement.addEventListener("animationend", listener);

    return () => {
      currentElement.removeEventListener("animationend", listener);
    };
  }, [children.length]);

  useEffect(() => {
    if (animStart) {
      return;
    }

    setTimeout(() => {
      setAnimStart(true);
    }, 100);
  }, [animStart, currentIndex]);

  return (
    <SliderContext.Provider value={childrenState}>
      <div
        className={cx("slider")}
        style={{
          fontFamily,
          fontSize,
          backgroundColor: bgColor,
        }}
      >
        <div
          style={{
            fontWeight: "lighter",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "40px",
          }}
        >
          <ListContainer
            ref={currentElementRef}
            currentIndex={currentIndex}
            start={animStart}
          >
            {[...new Array(listRepeatCount)].map((_, index) => (
              <Fragment key={index}>{children}</Fragment>
            ))}
          </ListContainer>
          <div style={{ fontWeight: 700, color: "transparent" }}>
            {rightText}
          </div>
        </div>

        <div
          style={{
            fontWeight: "bolder",
            position: "absolute",
            top: marginTop,
            right: 0,
            height: Height,
            overflow: "hidden",
            backgroundColor: bgColor,
            display: "flex",
            color: "#FFF",
            gap: "40px",
          }}
        >
          <div>Mint For</div>
          <ListContainer currentIndex={currentIndex} start={animStart}>
            <div
              style={{
                marginTop: -childHeight * topCount,
                textAlign: "end",
              }}
            >
              {children}
              {children}
            </div>
          </ListContainer>
          <div>{rightText}</div>
        </div>
      </div>
    </SliderContext.Provider>
  );
}

const ListContainer = forwardRef(
  ({ children, currentIndex, start, alignItems }, ref) => {
    const [childState] = useSlider();

    return (
      <ul
        ref={ref}
        style={{
          "--margin-from": `${-currentIndex * childState[0]}`,
          "--margin-to": `${-currentIndex * childState[0] - childState[0]}`,
          alignItems: alignItems,
        }}
        className={cx(start ? "swiper-animation" : "")}
      >
        {children}
      </ul>
    );
  }
);

export const SliderItem = ({ children, color, index }) => {
  const [, setSelf] = useSlider();

  const currentElementRef = useRef();

  useEffect(() => {
    setSelf((context) => ({
      [index]: currentElementRef.current.offsetHeight,
      ...context,
    }));
  }, [index, setSelf]);

  return (
    <li
      ref={currentElementRef}
      style={{
        color,
      }}
    >
      {children}
    </li>
  );
};

export const MobileSlider = ({
  children,
  fontFamily,
  fontSize = "110px",
  bgColor = "#000",
  Height = "130px",
}) => {
  const childrenState = useState({});

  const currentElementRef = useRef();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [animStart, setAnimStart] = useState(false);

  const [listRepeatCount, setListRepeatCount] = useState(1);

  const [marginTop, setmarginTop] = useState(0);

  useEffect(() => {
    const listHeight =
      Object.values(childrenState[0]).reduce((perv, next) => perv + next, 0) +
      1;

    const offsetHeight = currentElementRef.current.offsetHeight;

    const count = Math.ceil(offsetHeight / listHeight) + 1;

    setListRepeatCount(count);
  }, [childrenState]);

  const [childHeight, setChildHeight] = useState(0);
  const [topCount, settopCount] = useState(0);

  useEffect(() => {
    const offsetHeight = currentElementRef.current.offsetHeight;
    const childHeight = childrenState[0][0];

    if (!childHeight) {
      return;
    }

    setChildHeight(childHeight);

    const topCount = ((offsetHeight / childHeight + 0.5) / 2) >> 0;

    settopCount(topCount % Object.keys(childrenState[0]).length);

    const marginTop =
      (((offsetHeight / childHeight + 0.5) / 2) >> 0) * childHeight;

    setmarginTop(marginTop);
  }, [childrenState]);

  useEffect(() => {
    const currentElement = currentElementRef.current || {};

    const listener = () => {
      setCurrentIndex((v) => {
        return v + 1 === children.length ? 0 : v + 1;
      });
      setAnimStart(false);
    };

    currentElement.addEventListener("animationend", listener);

    return () => {
      currentElement.removeEventListener("animationend", listener);
    };
  }, [children.length]);

  useEffect(() => {
    if (animStart) {
      return;
    }

    setTimeout(() => {
      setAnimStart(true);
    }, 100);
  }, [animStart, currentIndex]);

  return (
    <SliderContext.Provider value={childrenState}>
      <div
        className={cx("slider")}
        style={{
          fontFamily,
          fontSize,
          backgroundColor: bgColor,
        }}
      >
        <div
          style={{
            fontWeight: "lighter",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "40px",
          }}
        >
          <ListContainer
            ref={currentElementRef}
            currentIndex={currentIndex}
            start={animStart}
            alignItems="center"
          >
            {[...new Array(listRepeatCount)].map((_, index) => (
              <Fragment key={index}>{children}</Fragment>
            ))}
          </ListContainer>
        </div>

        <div
          style={{
            fontWeight: "bolder",
            position: "absolute",
            top: marginTop,
            left: "50%",
            transform: "translate(-50%, 0)",
            height: Height,
            overflow: "hidden",
            backgroundColor: bgColor,
            display: "flex",
            color: "#FFF",
            gap: "40px",
          }}
        >
          <ListContainer currentIndex={currentIndex} start={animStart}>
            <div
              style={{
                marginTop: -childHeight * topCount,
                textAlign: "center",
              }}
            >
              {children}
              {children}
            </div>
          </ListContainer>
        </div>
      </div>
    </SliderContext.Provider>
  );
};
