import { Routes, Route } from "react-router-dom";

import Workspace from "src/pages/Workspace/index";

export default function PreviewRouter() {
  return (
    <Routes>
      <Route path="/" element={<Workspace />} />
    </Routes>
  );
}
