import { Routes, Route } from "react-router-dom";

// import Demo from "src/pages/Demo/index";
// import Playground from "src/pages/Playground";
// import LayoutDemo from "src/pages/Demo/LayoutDemo";
// import Locale from "src/pages/Demo/Locale";

// import NameList from "src/components/NameList/index";
import Workspace from "src/pages/Workspace/index";

export default function DevRouter() {
  return (
    <Routes>
      <Route path="/" element={<Workspace />} />
      {/* <Route path="/playground" element={<Playground />} />
      <Route path="/layout" element={<LayoutDemo />} />
      <Route path="/locale" element={<Locale />} />
      <Route path="/names" element={<NameList />} />
      <Route path='/workspace' element={<Workspace/>} /> */}
    </Routes>
  );
}
