import { useMemo } from "react";

import Slider, { SliderItem, MobileSlider } from "./components/Slider/index";

const names = [
  { name: "William", color: "rgb(150, 162, 77)" },
  { name: "Rebecca", color: "rgb(232, 82, 65)" },
  { name: "Michael", color: "rgb(5, 27, 245)" },
  { name: "Linda", color: "rgb(77, 162, 158)" },
  { name: "James", color: "rgb(5, 27, 245)" },
  { name: "Sarah", color: "rgb(246, 207, 85)" },
];

const names1 = [
  { name: "Satoshi", color: "rgb(65, 204, 217)" },
  { name: "Vitalik", color: "rgb(217, 67, 179)" },
  { name: "FTX", color: "rgb(93, 82, 255)" },
  { name: "Binance", color: "rgb(217, 67, 179)" },
  { name: "SBF", color: "rgb(255, 92, 209)" },
  { name: "WEB3", color: "rgb(93, 82, 255)" },

  { name: "NFT", color: "rgb(65, 204, 217)" },
  { name: "ERC721", color: "rgb(255, 92, 209)" },
  { name: "Punk", color: "rgb(93, 82, 255)" },
  { name: "Voxel", color: "rgb(255, 92, 209)" },
  { name: "Sandbox", color: "rgb(255, 92, 209)" },
  { name: "BAYC", color: "rgb(93, 82, 255)" },
  { name: "Otherside", color: "rgb(255, 92, 209)" },
];

export default function NameList() {
  return <List names={names1} />;
}

const List = ({ names }) => {
  const nameList = useMemo(
    () =>
      names.map(({ name, color }, index) => (
        <SliderItem color={color} index={index} key={index}>
          {name}
        </SliderItem>
      )),
    [names]
  );

  return (
    <div style={{ height: "100%" }}>
      <Slider
        bgColor="#09091B"
        fontSize="88px"
        // fontFamily="SF Pro Display"
        // 功能性注释
        Height="106px"
      >
        {nameList}
      </Slider>
    </div>
  );
};

export const MobileList = ({ names }) => {
  const nameList = useMemo(
    () =>
      names.map(({ name, color }, index) => (
        <SliderItem color={color} index={index} key={index}>
          {name}
        </SliderItem>
      )),
    [names]
  );
  return (
    <div style={{ height: "100%" }}>
      <MobileSlider fontSize="56px" fontFamily="SF Pro Display" Height="67px">
        {nameList}
      </MobileSlider>
    </div>
  );
};

export const MobileNameList = () => {
  return <MobileList names={names1} />;
};
