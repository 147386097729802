import React, { useState, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { Flex } from "src/components/basic/index";
import {
  Image,
  NavItem,
  APPButton,
  BaseTitle,
  BaseContent,
  Wrapper,
  NormalTitle,
  NormalSubtitle,
  DescriptionItem,
  SearchButton,
  BaseCard,
  DropDown,
  scrollToAnchor,
  handleComingClick,
} from "../base-component/index";
import Logo from "src/assets/img/logo.png";
import MobileCloseIcon from "src/assets/img/closeIcon.svg";
import MenuIcon from "src/assets/img/menu.svg";
import SearchIcon from "src/assets/img/Search.svg";
import DiscordIcon from "src/assets/img/discord-icon.svg";
import TwitterIcon from "src/assets/img/twitter-icon.svg";
import LightIcon from "src/assets/img/light-icon.svg";

import MobileDiscordIcon from "src/assets/img/mobile-discord-icon.svg";
import MobileTwitterIcon from "src/assets/img/mobile-twitter-icon.svg";
import MobileInsIcon from "src/assets/img/mobile-ins-icon.svg";

import ParnterO1Icon from "src/assets/parnterIcon/parnter-icon01.png";
import ParnterO2Icon from "src/assets/parnterIcon/parnter-icon02.png";
import ParnterO3Icon from "src/assets/parnterIcon/parnter-icon03.png";
import ParnterO4Icon from "src/assets/parnterIcon/parnter-icon04.png";
import ParnterO5Icon from "src/assets/parnterIcon/parnter-icon05.png";
import ParnterO6Icon from "src/assets/parnterIcon/parnter-icon06.png";
import ParnterO7Icon from "src/assets/parnterIcon/parnter-icon07.png";
import ParnterO8Icon from "src/assets/parnterIcon/parnter-icon08.png";
import ParnterO9Icon from "src/assets/parnterIcon/parnter-icon09.png";
import Parnter10Icon from "src/assets/parnterIcon/parnter-icon10.png";
import Parnter11Icon from "src/assets/parnterIcon/parnter-icon11.png";
import Parnter12Icon from "src/assets/parnterIcon/parnter-icon12.png";
import tempImg from 'src/assets/img/tempImg.png'
import MNS4KVideo from "src/assets/img/mns-4k.mp4";


import { Drawer } from "antd";


const cx = classNames.bind(styles);

export const IconList1 = [
  ParnterO1Icon,
  ParnterO2Icon,
  ParnterO3Icon,
  ParnterO4Icon,
  ParnterO5Icon,
  ParnterO6Icon,
];

export const IconList2 = [ 
  ParnterO7Icon,
  ParnterO8Icon,
  ParnterO9Icon,
  Parnter10Icon,
  Parnter11Icon,
  Parnter12Icon];

export const Header = () => {
  return (
    <Flex style={{ padding: "22px 0" }}>
      <Flex ai="center" jc="space-between" style={{ width: "100%" }}>
        <Flex gap="88px" ai="center">
          <Image src={Logo} width='154px' />
          <Nav />
        </Flex>
        <APPButton onClick={handleComingClick} />
      </Flex>
    </Flex>
  );
};

export const MobileHeader = () => {
  const [open, setOpen] = useState(false);

  // const showDrawer = () => {
  //   setOpen(true);
  // };

  const toggleDrawer = () => {
    setOpen(v => !v);
  }

  const onClose = () => {
    setOpen(false);
  };
  
  return <>
  <Flex ai='center' jc='space-between' style={{padding: '28px 24px', background: '#07041D'}}>
    <Image src={Logo} width='92px' />
    <Wrapper onClick={toggleDrawer} style={{cursor:'pointer'}}>
      { open  ? <Image src={MobileCloseIcon} /> : <Image src={MenuIcon}/> }
    </Wrapper>
  </Flex>
  <Drawer
        title="Drawer with extra actions"
        width="100vw"
        headerStyle={{ display: "none" }}
        closable
        bodyStyle={{ padding: 0 }}
        placement="right"
        style={{marginTop: 78}}
        open={open}>
          <MobileNav onClose={onClose} />
    </Drawer>
  </>
}

export const MobileNav = ({ onClose }) => {
  return (
    <Flex fd="column" jc="space-between" className={cx("mobile-nav")}>
      <MobileNavContent onClose={onClose} />
      <Wrapper>
        <MobileNavButton onClick={handleComingClick} />
        <Wrapper className={cx("nav-wrapper")}>
          <MobileNavIcons />
        </Wrapper>
      </Wrapper>
      
    </Flex>
  );
};

const MobileNavContent = ({onClose}) => {
  const handleClickOneStep = () => {
    scrollToAnchor('OneStep');
    onClose();
  }
  return <Flex fd='column'>
    <MobileNavItem onClick={handleComingClick}>overflow</MobileNavItem>
    <MobileNavItem onClick={handleClickOneStep}>oneStep</MobileNavItem>
    <MobileNavItem onClick={handleComingClick}>Developers</MobileNavItem>
    <MobileNavItem onClick={handleComingClick}>Blog</MobileNavItem>
  </Flex>
}

const MobileNavButton = ({onClick}) => {
  return <Wrapper className={cx("mobile-nav-button")} onClick={onClick}>App</Wrapper>;
};

const MobileNavItem = ({ children, onClick }) => {
  return <Wrapper className={cx("mobile-nav-item")} onClick={onClick}>{children}</Wrapper>;
};

const MobileNavIcons = () => {
  return <Flex gap='52px' ai='center' jc='center'>
    <NavIcon src={MobileDiscordIcon} href='' onClick={handleComingClick}/>
    <NavIcon src={MobileTwitterIcon} href='' onClick={handleComingClick}/>
    <NavIcon src={MobileInsIcon} href='' onClick={handleComingClick} />
  </Flex>
}

const NavIcon = ({ href, src, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      {/* <a href={href} target='blank'> */}
        <Image src={src} />
      {/* </a> */}
    </Wrapper>
  );
};

const Nav = () => {
  const handleClickOneStep = () => {
    scrollToAnchor('OneStep');
  }
  return (
    <Flex gap="30px" ai="center">
      <NavItem onClick={handleComingClick}>Overview</NavItem>
      <NavItem onClick={handleClickOneStep}>OneStep</NavItem>
      <NavItem onClick={handleComingClick}>Developers</NavItem>
      <NavItem onClick={handleComingClick}>Blog</NavItem>
      <DropDown
        options={[
          <DropDownItem text="Twitter" src={TwitterIcon} href="" />,
          <DropDownItem text="Discord" src={DiscordIcon} href="" />,
        ]}
      >
        <NavItem>Community</NavItem>
      </DropDown>
    </Flex>
  );
};

const DropDownItem = ({ text, src }) => {
  return (
    <Wrapper className={cx("dropdown-item")} onClick={handleComingClick}>
      <Image src={src} />
      {text}
    </Wrapper>
  );
};

export const MainPage = () => {
  return (
    <Flex fd="column" ai="center" style={{ width: "100%" }}>
      <NormalTitle className={cx("main-page-title")}>
        The First Metaverse Name Service
      </NormalTitle>
      <DescriptionList />
      <SearchInput onClick={handleComingClick} />
    </Flex>
  );
};

const DescriptionList = () => {
  return (
    <Flex
      gap="20px"
      style={{ margin: "40px 0 50px" }}
      fw="wrap"
      jc="center"
      className={cx('mobile-description-list')}
    >
      <DescriptionItem className={cx("description-item")}>
        Decentralization
      </DescriptionItem>
      <DescriptionItem className={cx("description-item")}>
        Omnichain
      </DescriptionItem>
      <DescriptionItem className={cx("description-item")}>
        DAO Governance
      </DescriptionItem>
      <DescriptionItem className={cx("description-item")}>
        Open Source
      </DescriptionItem>
    </Flex>
  );
};

const SearchInput = React.forwardRef(({ onClick }, ref) => {
  return (
    <>
      <Wrapper className={cx("search-input")}>
        <Wrapper className={cx("search-input-icon")}>
          <Image src={SearchIcon} />
        </Wrapper>
        <input type="text" placeholder="Get your .meta" className={cx("search-input-main")} ref={ref} />
        <Wrapper className={cx("search-input-button")}>
          <SearchButton onClick={onClick}>Search</SearchButton>
        </Wrapper>
      </Wrapper>
    </>
  );
});

export const VideoPage = () => {
  return (
      <Flex jc="center" fd="column" ai="center" >
        <NormalTitle className={cx("video-page-title")}>
          <Image src={LightIcon} width="48px" />
          OneStep Product Preview
        </NormalTitle>
        <NormalSubtitle className={cx("video-page-subtitle")}>
          The first Metaverse omnichain DID parsing product, breaking the Web2 &
          Web3 barrier
        </NormalSubtitle>
        <VideoComponent className={cx("video-area")} src={MNS4KVideo} />
      </Flex>
  );
};

export const Partners = () => {
  return (
    <Wrapper>
      <Flex fd="column" ai="center">
        <NormalTitle className={cx("partners-title")}>
          Platforms We Support
        </NormalTitle>
        <NormalSubtitle className={cx("partners-subtitle")}>
          Not only supports the omnichain metaverse platform, but also supports
          all DID platforms
        </NormalSubtitle>
      </Flex>
    </Wrapper>
  );
};

export const PartnerItem = ({ reverse, IconList, small }) => {
  const IconListComponent = useMemo(() => {
    return IconList.map((item, index) => {
      return (
        <Wrapper className={cx("partner-item")} key={index}>
          <Image src={item} width={ small ? '107px' : '214px'} />
        </Wrapper>
      );
    });
  }, [IconList, small]);

  const [animationState, setAnimationState] = useState(false);
  const handleEnd = useCallback((e) => {
    setAnimationState((v) => !v);
  }, []);

  return (
    <div
      className={cx(
        `parnter-container${reverse ? "-reverse" : ""}${+animationState}`
      )}
      onAnimationEnd={handleEnd}
      style={{ "--child-count": IconListComponent.length }}
    >
      <Flex gap={small ?' 24px' : '60px'}>
        {IconListComponent}
        {IconListComponent}
        {IconListComponent}
      </Flex>
    </div>
  );
};

export const CardOwe = () => {
  return <BaseCard className={cx('card-one')}>
    <CardContent title='.meta: Metaverse Name Service' member='1'>
      <Flex fd='column' gap='10px'>
        <BaseContent className={cx('card-content')}>Unified identity of metaverse users</BaseContent>
        <BaseContent className={cx('card-content')}>Unified Address of metaverse Land</BaseContent>
        <BaseContent className={cx('card-content')}>Unified Name of metaverse Characters</BaseContent>
      </Flex>
    </CardContent>
    <CardVideo/>
  </BaseCard>
}

const CardVideo = () => {
  // return <VideoComponent className={cx('card-video')} src={MNS4KVideo}/>
  return <Wrapper className={cx('card-video')}>
    <Image src={tempImg} width="100%"/>
  </Wrapper>
}

const CardContent = ({style, title, member, children}) => {
  return <Flex fd='column' gap='16px' style={style}>
    <Wrapper className={cx(`card-tab-number-${member}`)}>#{member}</Wrapper>
    <BaseTitle className={cx('card-title')}>{title}</BaseTitle>
    {children}
  </Flex>
}

const CardTwo = () => {
  return <BaseCard className={cx('card-two')}>
    <CardContent title='One Step: One key direct access to all DIDs' member='2'>
      <Flex fd='column' gap='10px'>
        <BaseContent className={cx('card-content')}>One click to resolve all <span>DIDs</span> </BaseContent>
        <BaseContent className={cx('card-content')}>First <span>Metaverse</span> portal</BaseContent>
        <BaseContent className={cx('card-content')}>Unified DID user center</BaseContent>
      </Flex>
    </CardContent>
  </BaseCard>
}

const CardThree = () => {
  return <BaseCard className={cx('card-three')}>
    <CardContent title='SDK: one-stop access to all DIDs' member='3'>
      <Flex fd='column' gap='10px'>
        <BaseContent className={cx('card-content')}>Aggregate all DID services</BaseContent>
        <BaseContent className={cx('card-content')}>Development document of handlebar</BaseContent>
        <BaseContent className={cx('card-content')}>Open source decentralized contract</BaseContent>
      </Flex>
    </CardContent>
  </BaseCard>
}

export const CardPage = () => {
  return (
    <>
      <CardOwe />
      <Flex
        style={{ marginTop: 40 }}
        gap="40px"
        className={cx("card-container")}
      >
        <CardTwo />
        <CardThree />
      </Flex>
    </>
  );
};

export const Footer = () => {

  return (
    <Flex className={cx("footer")} gap="262px">
      <Flex className={cx("footer-left")} gap="16px" fd="column">
        <Image src={Logo} width='154px' />
        <BaseContent className={cx("footer-left-content")}>
          The First Metaverse Name Service
        </BaseContent>
      </Flex>
      <Flex className={cx("footer-right")} fw="wrap">
        <Wrapper>
          <BaseTitle className={cx("footer-nav-title")}>Marketplace</BaseTitle>
          <Flex fd="column" gap="24px">
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>Opensea</BaseContent>
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>
              Element
            </BaseContent>
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>
              X2Y2
            </BaseContent>
          </Flex>
        </Wrapper>
        <Wrapper>
          <BaseTitle className={cx("footer-nav-title")}>Resources</BaseTitle>
          <Flex fd="column" gap="24px">
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>Docs</BaseContent>
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>
              Blog
            </BaseContent>
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>
              Forum
            </BaseContent>
          </Flex>
        </Wrapper>
        <Wrapper>
          <BaseTitle className={cx("footer-nav-title")}>Find Us</BaseTitle>
          <Flex fd="column" gap="24px">
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>Twitter</BaseContent>
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>
              Discord
            </BaseContent>
            <BaseContent className={cx("footer-nav-item")} onClick={handleComingClick}>
              Mirror
            </BaseContent>
          </Flex>
        </Wrapper>
      </Flex>
    </Flex>
  );
};

const VideoComponent = ({ className, src }) => {
  return (
    <video
      className={cx(className)}
      width="100%"
      height="100%"
      autoPlay
      loop
      muted
      playsInline
    >
      <source src={src} type="video/mp4"></source>
    </video>
  );
};
