import React from "react";
import "./workspace.css";
import styles from "./workspace.module.scss";
import classNames from "classnames/bind";
import {
  Header as ComponentHeader,
  MobileHeader,
  VideoPage,
  MainPage,
  Partners,
  CardPage,
  Footer as ComponentFooter,
  PartnerItem,
  IconList1,
  IconList2,
} from "src/components/workspace-component/combination-component/index";
import {  Wrapper } from "src/components/workspace-component/base-component/index";
import { Layout, Header, Main, Content, Footer, useIsMobile } from "src/layouts/index";
import { Flex } from "src/components/basic/index";
import NameList, { MobileNameList } from "src/components/NameList/index";
import useWebGLBlobs from "src/components/WebGLBlobs/useWebGLBlobs";

const cx = classNames.bind(styles);

export default function Workspace() {

  return (
    <>
      <Wrapper className={cx("page-container")}>
        <Layout maxWidth="9999px">
          <Header device="pc" earlyResponse>
            <Flex fd="column" ai="center">
              <Wrapper className={cx("container")}>
                <ComponentHeader />
              </Wrapper>
            </Flex>
          </Header>
          <Header device="mobile" earlyResponse position='sticky'>
            <MobileHeader />
          </Header>
          <Main width="100">
            <Content>
              <ContentComponent />
            </Content>
          </Main>
          <Footer>
            <FooterShow />
          </Footer>
        </Layout>
      </Wrapper>
    </>
  );
}
const AnimationContainer = ({ children }) => {
  return <Wrapper className={cx("animation-container")}>{children}</Wrapper>;
};

const Video = () => {
  return (
    <Wrapper className={cx("video-container")} id='OneStep'>
      <Wrapper className={cx("container")} >
        <VideoPage />
      </Wrapper>
    </Wrapper>
  );
};

const Parnters = () => {
  const ismobile = useIsMobile();
  return (
    <Wrapper className={cx("parnters-container")}>
      <Wrapper className={cx("container")}>
        <Partners />
      </Wrapper>
      { ismobile ? <MobileParnters/> : <WebParnters/>}
    </Wrapper>
  );
};

const WebParnters = () => {
  return <>
    <AnimationContainer>
        <PartnerItem IconList={IconList1} />
      </AnimationContainer>

      <AnimationContainer>
        <PartnerItem IconList={IconList2} reverse />
      </AnimationContainer>
  </>
}

const MobileParnters = () => {
  return <>
      <AnimationContainer>
        <PartnerItem IconList={IconList1} small />
      </AnimationContainer>

      <AnimationContainer>
        <PartnerItem IconList={IconList1.slice().reverse()} reverse small />
      </AnimationContainer>

      <AnimationContainer>
        <PartnerItem IconList={IconList2} small />
      </AnimationContainer>
      
      <AnimationContainer>
        <PartnerItem IconList={IconList2.slice().reverse()} reverse small />
      </AnimationContainer>
  </>
}

const Cards = () => {
  return (
    <Wrapper className={cx("cards-container")}>
      <Wrapper className={cx("container")}>
        <CardPage />
      </Wrapper>
    </Wrapper>
  );
};

const WebGLBlobsComponent = () => {
  useWebGLBlobs();
  return null;
}

const MainShow = () => {
  // useWebGLBlobs();
  const ismobile = useIsMobile();
  // useEffect(() => {
  //   const canvasElement = document.querySelector('canvas');
  //   console.log(canvasElement);
  //   if(ismobile && canvasElement) canvasElement.remove();

  // }, [ismobile])
  

  return (
    <Wrapper className={cx("main-page-container")}>
      <div className={cx("main-page-container-bg")}></div>
      <MainPage />
      {
        ismobile ? null : <WebGLBlobsComponent/>
      }
    </Wrapper>
  );
};

const FooterShow = () => {
  return (
    <Flex fd="cloumn" jc="center" className={cx("footer-container")}>
      <Wrapper className={cx("container")}>
        <ComponentFooter />
      </Wrapper>
    </Flex>
  );
};

const ContentComponent = () => {
  const ismobile = useIsMobile();
  return (
    <>
      <MainShow />
      <Flex fd="column" jc="center" ai="center" gap="100px">
        <Video />
        <Parnters />
        <Cards />
      </Flex>
      { ismobile ? <MobileNameListComponent /> : <NameListComponent /> }
     </>
  );
};

const MobileNameListComponent = () => {
  return (
    <Wrapper style={{ background: "#09091B" }} className={cx("full-container")}>
      <Flex
        fd="column"
        jc="center"
        ai="center"
        gap="48px"
        style={{ margin: "100px 0",background: 'rgba(7, 4, 29, 1)' }}
      >
        <Wrapper className={cx('mobile-mini-text')}>Mint For</Wrapper>
        <Wrapper
          className={cx("container")}
          style={{ margin: "auto", height: 250, position: 'relative' }}
        >
          <Shadow />
          <MobileNameList />
          <Shadow origin="bottom" />
        </Wrapper>
        <Wrapper className={cx('mobile-mini-text')}>.Meta</Wrapper>
      </Flex>
    </Wrapper>
  );
};

const NameListComponent = () => {
  return (
    <Wrapper style={{ background: "#09091B" }} className={cx("full-container")}>
      <Wrapper
        className={cx("container")}
        style={{ margin: "auto", height: 900, position: "relative" }}
      >
        <Shadow />
        <NameList />
        <Shadow origin="bottom" />
      </Wrapper>
    </Wrapper>
  );
};

const Shadow = ({ origin = "top" }) => {
  return <div className={cx("shadow", origin)}></div>;
};


